import { EPropsTypes } from './actionsTypes';
import { PropReducerState } from './interface';

const initialState: PropReducerState = {
    fetchPropDbLoading: false,
    propDB: undefined,
    error: undefined,
    updatePropEnvLoading: false,
};

const propReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EPropsTypes.PROP_UPDATE_ENV_START:
            return {
                ...state,
                updatePropEnvLoading: true,
            };
        case EPropsTypes.PROP_UPDATE_ENV_END:
            return {
                ...state,
                propDB: payload,
                updatePropEnvLoading: false,
            };
        case EPropsTypes.PROP_UPDATE_ENV_ERROR:
            return {
                ...state,
                error: payload,
            };

        case EPropsTypes.PROP_GET_NAMESPACE_OPTIONS_START:
            return {
                ...state,
                fetchPropDbLoading: true,
            };
        case EPropsTypes.PROP_GET_NAMESPACE_OPTIONS_END:
            return {
                ...state,
                propDB: payload,
                fetchPropDbLoading: false,
            };
        case EPropsTypes.PROP_GET_NAMESPACE_OPTIONS_ERROR:
            return {
                ...state,
                error: payload,
            };
        case EPropsTypes.PROP_ADD_GOAL_START:
            return state;
        case EPropsTypes.PROP_ADD_GOAL_END:
            return {
                ...state,
                propDB: payload,
            };
        case EPropsTypes.PROP_ADD_GOAL_ERROR:
            return { ...state, error: payload };
        case EPropsTypes.PROP_ADD_GOAL_RESET:
            return { ...state, error: undefined };
        case EPropsTypes.PROP_UPDATE_GOAL_START:
            return state;
        case EPropsTypes.PROP_UPDATE_GOAL_END:
            return {
                ...state,
                propDB: payload,
            };
        case EPropsTypes.PROP_UPDATE_GOAL_ERROR:
            return { ...state, error: payload };
        case EPropsTypes.PROP_UPDATE_GOAL_START_TREE:
            return state;
        case EPropsTypes.PROP_UPDATE_GOAL_END_TREE: {
            const newState = {
                ...state,
                propDB: {
                    ...(state?.propDB || {}),
                    PROP_OPTIONS: payload.PROP_OPTIONS,
                },
            };
            return newState;
        }
        case EPropsTypes.PROP_UPDATE_GOAL_ERROR_TREE:
            return { ...state, error: payload };
        case EPropsTypes.PROP_DELETE_GOAL_START:
            return state;
        case EPropsTypes.PROP_DELETE_GOAL_END:
            return {
                ...state,
                propDB: payload,
            };
        case EPropsTypes.PROP_DELETE_GOAL_ERROR:
            return { ...state, error: payload };
        case EPropsTypes.PROP_DELETE_CHALLENGE_START:
            return state;
        case EPropsTypes.PROP_DELETE_CHALLENGE_END:
            return {
                ...state,
                propDB: payload,
            };
        case EPropsTypes.PROP_DELETE_CHALLENGE_ERROR:
            return { ...state, error: payload };
        case EPropsTypes.PROP_ADD_CHALLENGE_START:
            return state;
        case EPropsTypes.PROP_ADD_CHALLENGE_END:
            return {
                ...state,
                propDB: payload,
            };
        case EPropsTypes.PROP_ADD_CHALLENGE_ERROR:
            return { ...state, error: payload };
        case EPropsTypes.PROP_UPDATE_CHALLENGE_START:
            return state;
        case EPropsTypes.PROP_UPDATE_CHALLENGE_END:
            return {
                ...state,
                propDB: payload,
            };
        case EPropsTypes.PROP_UPDATE_CHALLENGE_ERROR:
            return { ...state, error: payload };
        case EPropsTypes.PROP_ADD_PACKAGE_START:
            return state;
        case EPropsTypes.PROP_ADD_PACKAGE_END:
            return {
                ...state,
                propDB: payload,
            };
        case EPropsTypes.PROP_ADD_PACKAGE_ERROR:
        case EPropsTypes.PROP_DELETE_PACKAGE_START:
            return state;
        case EPropsTypes.PROP_DELETE_PACKAGE_END:
            return {
                ...state,
                propDB: payload,
            };
        case EPropsTypes.PROP_DELETE_PACKAGE_ERROR:
            return { ...state, error: payload };
        case EPropsTypes.PROP_UPDATE_PACKAGE_START:
            return state;
        case EPropsTypes.PROP_UPDATE_PACKAGE_END:
            return {
                ...state,
                propDB: payload,
            };
        case EPropsTypes.PROP_UPDATE_PACKAGE_ERROR:
            return { ...state, error: payload };
        default:
            return state;
    }
};

export default propReducer;
