/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Au10tixSettings } from './au10tix-settings';
import { EmailRecipients } from './email-recipients';
import { PaymentTypesFlagsNewEntity } from './payment-types-flags-new-entity';
import { SmtpCredentials } from './smtp-credentials';
import { WebHookEntity } from './web-hook-entity';
import { WebTraderConfig } from './web-trader-config';

/**
 *
 * @export
 * @interface ServerEnvDBI
 */
export class ServerEnvDBI {
    /**
     *
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof ServerEnvDBI
     */
    CRM_REAL_GROUP_NAMES: { [key: string]: { [key: string]: object } };
    /**
     *
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof ServerEnvDBI
     */
    CRM_DEMO_GROUP_NAMES: { [key: string]: { [key: string]: object } };
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ServerEnvDBI
     */
    FEATURES_FLAGS: { [key: string]: boolean };
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ServerEnvDBI
     */
    REGISTER_FLAGS: { [key: string]: boolean };
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ServerEnvDBI
     */
    BANK_ACCOUNT_FLAGS: { [key: string]: boolean };
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ServerEnvDBI
     */
    PAYMENT_TYPES_FLAGS: { [key: string]: boolean };
    /**
     *
     * @type {number}
     * @memberof ServerEnvDBI
     */
    PORT: number;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    CRM_API_URL: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    GOOGLE_AUTH_URL: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    GOOGLE_CLIENT_ID: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    GOOGLE_RECAPTCHA_SECRET: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    FACEBOOK_AUTH_URL: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    JWT_SECRET: string;
    /**
     *
     * @type {number}
     * @memberof ServerEnvDBI
     */
    JWT_EXPIRE: number;
    /**
     *
     * @type {number}
     * @memberof ServerEnvDBI
     */
    JWT_REFRESH_BEFORE: number;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    REMOTE_JWT: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    mongo_user: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    mongo_password: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    mongo_connection_string: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    redis_connection_string: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    CRM_OWNER_USER_ID: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    CRM_BUSINESS_UNIT_NAME: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    CRM_ORGANIZATION_NAME: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    CRM_REAL_GROUP_NAME_DEFAULT: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    CRM_DEMO_GROUP_NAME_DEFAULT: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    CRM_TRADING_PLATFORM_ID_REAL: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    CRM_TRADING_PLATFORM_ID_DEMO: string;
    /**
     *
     * @type {Array<WebTraderConfig>}
     * @memberof ServerEnvDBI
     */
    WEBTRADER_CONFIG: Array<WebTraderConfig>;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    SUPER_WEBTRADER_SECRET: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    TRADING_ANALYTICS_URL: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    TRADING_ANALYTICS_ENCRYPTION_KEY: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    TRADING_ANALYTICS_WEB_TV_XML: string;
    /**
     *
     * @type {Array<PaymentTypesFlagsNewEntity>}
     * @memberof ServerEnvDBI
     */
    PAYMENT_TYPES_FLAGS_NEW: Array<PaymentTypesFlagsNewEntity>;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    SUMSUB_URL: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    SUMSUB_TOKEN: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    SUMSUB_SECRET: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    SUMSUB_FLOW: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    SUMSUB_LEVEL: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    SUMSUB_WEBHOOK_SECRET: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    PAYMENT_SERVICE_URL: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    PAYMENT_SERVICE_BRAND: string;
    /**
     *
     * @type {boolean}
     * @memberof ServerEnvDBI
     */
    SWAGGER_FLAG: boolean;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    KYC_TYPE: ServerEnvDBIKYCTYPEEnum;
    /**
     *
     * @type {number}
     * @memberof ServerEnvDBI
     */
    MIN_DEPOSIT?: number;
    /**
     *
     * @type {number}
     * @memberof ServerEnvDBI
     */
    MAX_DEPOSIT?: number;
    /**
     *
     * @type {WebHookEntity}
     * @memberof ServerEnvDBI
     */
    WEB_HOOK_POST_REGISTER?: WebHookEntity;
    /**
     *
     * @type {Array<string>}
     * @memberof ServerEnvDBI
     */
    REGISTRATION_BLOCKED_COUNTRIES?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ServerEnvDBI
     */
    WHITELIST_IPS?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    REGISTER_ACCOUNT_TYPE?: ServerEnvDBIREGISTERACCOUNTTYPEEnum;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    TRADING_4_PRO_LEVEL: ServerEnvDBITRADING4PROLEVELEnum;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    EMAIL_TEMPLATES_RISK_WARNING: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    RATE_LIMIT_TTL: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    RATE_LIMIT_MAX_REQUESTS: string;
    /**
     *
     * @type {number}
     * @memberof ServerEnvDBI
     */
    RATE_LIMIT_MAX_USERS: number;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    IDENTIFY_SERVICE_URL: string;
    /**
     *
     * @type {string}
     * @memberof ServerEnvDBI
     */
    IDENTIFY_SERVICE_SECRET: string;
    /**
     *
     * @type {Au10tixSettings}
     * @memberof ServerEnvDBI
     */
    AU10TIX: Au10tixSettings;
    /**
     *
     * @type {SmtpCredentials}
     * @memberof ServerEnvDBI
     */
    SMTP_CREDENTIALS: SmtpCredentials;
    /**
     *
     * @type {EmailRecipients}
     * @memberof ServerEnvDBI
     */
    EMAIL_RECIPIENTS: EmailRecipients;
}

/**
 * @export
 * @enum {string}
 */
export enum ServerEnvDBIKYCTYPEEnum {
    Lfs = 'lfs',
    Fsca = 'fsca',
    Wgm = 'wgm',
    Kimbo = 'kimbo',
    Aml = 'aml',
    FscaWgm = 'fsca-wgm',
    ByRegulation = 'by-regulation',
    AltRegulation = 'alt-regulation',
}
/**
 * @export
 * @enum {string}
 */
export enum ServerEnvDBIREGISTERACCOUNTTYPEEnum {
    RealAndDemo = 'real-and-demo',
    DemoAndReal = 'demo-and-real',
    Real = 'real',
    Demo = 'demo',
}
/**
 * @export
 * @enum {string}
 */
export enum ServerEnvDBITRADING4PROLEVELEnum {
    Off = 'off',
    Basic = 'basic',
    Vip = 'vip',
}
